import React, { useState } from "react";
import "./followuptablecard.css";
import Release from "../../assets/down-arrow.png";
import { isEmptyObject } from "jquery";

const FollowUpCenterTableCard = ({
    center_exam = [
        {
            id: 1,
            exam_name: "اختبار تاني محاضره",
            lecture_name: "SALAH 101",
            exam_date: "2024-09-05 00:00:00",
            result_percentage: 85,
            file_url: "https://example.com/results/student1.pdf",
        },
        {
            id: 1,
            exam_name: "اختبار تاني محاضره",
            lecture_name: "SALAH 101",
            exam_date: "2024-09-05 00:00:00",
            result_percentage: 85,
            file_url: "https://example.com/results/student1.pdf",
        },
    ],
}) => {
    const [showCardDetails, setShowCardDetails] = useState(false);

    return (
        <div className="relative">
            <div
                onClick={() => setShowCardDetails(!showCardDetails)}
                className={`w-full z-10 relative flex justify-center items-center px-2 md:px-0  py-5 smooth transform hover:cursor-pointer hover:scale-[100.5%] bg-gray-100 dark:bg-[#93A3BC]/50 shadow-md   clr-text-primary ${
                    showCardDetails && `shadow-md`
                }`}
            >
                <div className="relative w-fit flex h-fit  items-center justify-start md:justify-center ">
                    <h1 className="text-xl font-bold mx-auto">
                        جميع نتائج امتحان :{" "}
                        <span className="text-[#FFAD00] text-stroke">السنتر</span>
                    </h1>
                </div>
                <div className="h-full flex-center-both">
                    <img
                        src={Release}
                        className={`transform smooth text-rose-500 ${
                            showCardDetails ? "pb-1 -rotate-180 " : "pt-0.5 rotate-0"
                        } absolute left-5 md:left-10 w-6 md:w-8`}
                        alt="releaseDay"
                    />
                    <img
                        src={Release}
                        className={`transform smooth text-rose-500 ${
                            showCardDetails ? "pb-1 -rotate-180 " : "pt-0.5 rotate-0"
                        } absolute right-5 md:right-10 w-6 md:w-8`}
                        alt="releaseDay"
                    />
                </div>
            </div>

            {/* Table Details */}
            {showCardDetails && (
                <div
                    className={`fade-down-in py-5 px-3 bg-slate-100 dark:bg-slate-800 rounded-xl smooth clr-text-primary`}
                >
                    {center_exam.length > 0 ? (
                        <div
                            className={`fade-down-in h-full py-14 px-5  overflow-x-auto bg-slate-100 dark:bg-slate-800 -mt-7 rounded-xl smooth clr-text-primary`}
                        >
                            <table className="table-auto w-full table-study-style rounded-xl">
                                <thead className="bg-gray-200 dark:bg-gray-700 smooth">
                                    <tr>
                                        <th className="h-20 text-center clr-text-primary smooth">
                                            اسم الامتحان
                                        </th>
                                        <th className="h-20 text-center clr-text-primary smooth">
                                            المحاضرة
                                        </th>
                                        <th className="h-20 text-center clr-text-primary smooth">
                                            تاريخ الامتحان
                                        </th>
                                        <th className="h-20 text-center clr-text-primary smooth">
                                            النتيجة
                                        </th>
                                        <th className="h-20 text-center clr-text-primary smooth">
                                            ملف النتيجة
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {center_exam.map((center_exam) => (
                                        <tr
                                            key={center_exam.id}
                                            className={`${
                                                center_exam.id % 2 === 0
                                                    ? "bg-white dark:bg-gray-600"
                                                    : "bg-gray-100 dark:bg-gray-700"
                                            } smooth `}
                                        >
                                            <td className="h-20 text-center clr-text-primary smooth">
                                                {center_exam.exam_name}
                                            </td>
                                            <td className="h-20 text-center clr-text-primary smooth">
                                                {center_exam.lecture_name}
                                            </td>
                                            <td className="h-20 text-center clr-text-primary smooth">
                                                {new Date(center_exam.exam_date).toLocaleDateString(
                                                    "ar-EG"
                                                )}
                                            </td>
                                            <td className="h-20 text-center clr-text-primary smooth">
                                                {center_exam.result_percentage}%
                                            </td>
                                            <td className="h-20 text-center clr-text-primary smooth">
                                                <a
                                                    href={center_exam.file_url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 underline"
                                                >
                                                    عرض الملف
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p className="text-center text-gray-500">لا توجد نتائج حتى الآن.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default FollowUpCenterTableCard;
