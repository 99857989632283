import React, { useState } from "react";
import Button from "./Button";
import CenterIcon from "./CenterIcon";
import Container from "./Container";
import FollowUpTableCard from "./FollowUpTableCard";
import { ReactComponent as HeaderDecore } from "../../assets/parent-dashboard-assets/headerDecore.svg";
import FollowUpCenterTableCard from "./FollowUpCenterTableCard";
import { isEmptyObject } from "jquery";
const CourseFollowUpSection = ({ course, followUpData }) => {
    const [showWeeks, setShowWeeks] = useState(false);

    console.log("course", course);
    console.log("course", course);
    if (followUpData.courses < 1 && followUpData.center_exam_results < 1) {
        return (
            <div className="text-center py-10">
                <h2 className="text-2xl font-bold text-stone-800 dark:text-stone-100 smooth font-expo">
                    لا توجد بيانات او نتائج تخص نجلك حتي الأن
                </h2>
            </div>
        );
    } else {
        return (
            <div className=" bg-[#F8F8F8] border border-black dark:bg-slate-900 smooth flex-center-both flex-col pt-10 shadow-md rounded-xl px-4">
                <div>
                    <div className=" font-dg  text-center md:text-5xl text-3xl pt-5 pb-10 flex-center-both space-x-3 space-x-reverse">
                        <span className="clr-text-primary smooth">
                            تفاصيل احصائيات
                            <span className="text-stone-500"> نجلك !</span>
                        </span>
                    </div>
                </div>

                {followUpData.center_exam_results &&
                    followUpData.center_exam_results.length > 0 && (
                        <div className="flex flex-col gap-2 py-5 w-full">
                            <FollowUpCenterTableCard
                                center_exam={followUpData.center_exam_results}
                            />
                        </div>
                    )}

                {!isEmptyObject(course) ? (
                    <>
                        <OwnTitle
                            showWeeks={showWeeks}
                            setShowWeeks={setShowWeeks}
                            isToShowWeeks={course.sections.length}
                            title={course.name}
                        />

                        <Container className="flex-center-both w-full gap-8 flex-col">
                            {course.sections.length > 0 ? (
                                <>
                                    {!showWeeks ? (
                                        <></>
                                    ) : (
                                        <>
                                            <div className="flex flex-col gap-2 w-full">
                                                {course.sections.map((section) => (
                                                    <FollowUpTableCard
                                                        key={section.id}
                                                        section={section}
                                                    />
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                ""
                            )}
                        </Container>
                    </>
                ) : followUpData.courses.length > 0 ? (
                    <div className="text-center py-10">
                        <h2 className="text-2xl font-bold text-stone-800 dark:text-stone-100 smooth font-expo">
                            أختر الكورس أولا لإظهار كل ما يخص نجلك من إحصائيات ونتائج
                        </h2>
                    </div>
                ) : (
                    <div className="text-center py-10">
                        <h2 className="text-2xl font-bold text-stone-800 dark:text-stone-100 smooth font-expo">
                            لا توجد كورسات مشترك بها نجلك حتي الأن
                        </h2>
                    </div>
                )}
            </div>
        );
    }
};

const OwnTitle = ({ showWeeks, setShowWeeks, title, isToShowWeeks = true }) => {
    return (
        <>
            <div className="w-full flex flex-col flex-center-both space-y-5">
                <div className="relative h-fit flex-center-both group">
                    <h1 className=" md:font-w-bold clr-text-primary smooth z-10 pb-4 text-center text-2xl sm:text-3xl md:text-3xl ">
                        {title}
                        {/* <span className="font-w-bold">"{weekStatus}"</span> */}
                    </h1>
                    {/* <div className="absolute w-[320px] md:w-[500px]">
                        <HeaderDecore className="opacity-70 group-hover:blur-sm smooth fill-black dark:fill-slate-400 smooth" />
                    </div> */}
                </div>
                <div className="pt-2">
                    {isToShowWeeks ? (
                        <Button
                            className="flex-center-both space-x-2 font-w-bold space-x-reverse border-stone-500 text-stone-500 smooth group bg- border underline"
                            onClick={() => setShowWeeks(!showWeeks)}
                        >
                            {!showWeeks ? (
                                <span className="">عرض المحتوى</span>
                            ) : (
                                <span className="">إخفاء المحتوى</span>
                            )}
                            <CenterIcon
                                className={`transform smooth text-stone-500 ${
                                    showWeeks ? "pb-1 -rotate-180 " : "pt-0.5 rotate-0"
                                }`}
                                icon="bxs:up-arrow"
                            />
                        </Button>
                    ) : (
                        <div className="font-w-bold bg-third-container border-secondary-container smooth rounded-md border clr-text-primary px-5 py-2">
                            <div>لم يتم نزول المحتوى في هذا الكورس حتى الآن</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CourseFollowUpSection;
